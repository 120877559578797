import React from "react";
import OurServiceSection from "../components/OurServiceSection";

const ServicePage = () => {
  return (
    <div>
      <OurServiceSection />
    </div>
  );
};

export default ServicePage;
